.about-outer-container {
  background-color: #383838;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  padding-bottom: 55px;
  width: 100%;
}

.about-clip-path-top {
  background-color: #383838;
  clip-path: polygon(0% 50%, 0% 100%, 100% 100%);
  height: 150px;
  margin-top: -149px;
  width: 100%;
}

.profile-pic-container {
  display: flex;
  height: auto;
  justify-content: center;
  width: 30%;
}

.profile-pic {
  border-radius: 100px;
  height: 200px;
  margin: 15% 0% 0% 20%;
  width: 200px;
}

.about-info-container {
  padding: 3% 10% 0% 5%;
  width: 70%;
}

.about-text {
  color: #FFF;
  font-family: 'Muli';
  letter-spacing: 0.7px;
  line-height: 180%;
}

.about-text-2 {
  margin-top: 12px;
}

.email-text {
  text-decoration-line: underline;
}

.contact-info-container {
  margin-top: 5%;
}

.resume-button {
  background-color: #383838;
  border: 2px solid #a72db9;
  border-radius: 26px;
  color: #FFF;
  font-size: 100%;
  height: 50px;
  margin-top: 5%;
  padding: 0px 20px;
  width: auto;
}

.resume-button:hover {
  background-color: #a72db9;
  color: #FFF;
  cursor: pointer;
}

.tech-skills-link {
  font-family: 'Muli';
  text-decoration: underline;
}

.tech-skills-link:hover {
  cursor: pointer;
}

.about-wrapper {
  background-color: #383838;
}

@media only screen and (max-width: 1000px) {
  
  .about-clip-path-top {
    height: 113px;
    margin-top: -112px;
  }

}

@media only screen and (max-width: 800px) {

  .about-outer-container {
    height: auto;
    padding-bottom: 50px;
  }

  .profile-pic-container {
    display: none;
  }

  .about-info-container {
    padding-left: 50px;
  }

  .resume-button {
    margin-top: 8%;
  }

}

@media only screen and (max-width: 500px) {

  .about-clip-path-top {
    height: 75px;
    margin-top: -74px;
  }

}


